import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent, CardHeader, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/EditOutlined'

import dateFr from './../lib/date'
import { AppContext } from '../context/AppContext'
import useTheme from '../themes/material'

export default function Actualite ({ item, editabled }) {
  const app = useContext(AppContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const editUrl = '/gestion-des-actualites/edition'

  const title = item.titre
  const subheader = 'Publié le ' + dateFr(item.publishedAt)
  const content = (
    <CardContent
      dangerouslySetInnerHTML={{ __html: item.texte }}
    />
  )

  return (
    <Card sx={{ padding: theme.spacing(2, 2, 0, 2) }}>
      <CardHeader
        action={ editabled && (
          <Tooltip title="Modifier l'actualité">
            <IconButton onClick={ () => { navigate(editUrl, { state: item }) } }>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) }
        title={ title }
        subheader={ subheader }
      />
      { content }
    </Card>
  )
}
