import React, { useContext } from 'react'

import { Box } from '@mui/material'

import useTheme from './../themes/material'
import './Loader.css'
import { ColorModeContext } from '../context/ColorModeContext'

export default function Loader ({ fullPage, center, size }) {
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme()
  const sxInner = { backgroundColor: colorMode === 'dark' ? '#ffffff' : theme.palette.primary.main }
  size = size || 'md'

  const loader = <Box className={`loader ${size}`}>
    <div className="loader-div">
      <div className="loader-div-div">
        <Box className="loader-span one h6" sx={ sxInner }></Box>
        <Box className="loader-span two h3" sx={ sxInner }></Box>
      </div>
    </div>
    <div className="loader-div">
      <div className="loader-div-div">
        <Box className="loader-span one h1" sx={ sxInner }></Box>
        <Box className="loader-span two h4" sx={ sxInner }></Box>
      </div>
    </div>
    <div className="loader-div">
      <div className="loader-div-div">
        <Box className="loader-span one h5" sx={ sxInner }></Box>
        <Box className="loader-span two h2" sx={ sxInner }></Box>
      </div>
    </div>
  </Box>

  if (fullPage) {
    return (
      <Box sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        pointerEvents: 'none'
      }}>
        { loader }
      </Box>
    )
  } else if (center) {
    return (
      <Box sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        { loader }
      </Box>
    )
  }

  return (
    loader
  )
}
