import { TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { useState } from 'react'

export const yearsBefore = 10
export const yearsAfter = 2

export const dateMinImportant = dayjs(process.env.REACT_APP_DATE_NOW).subtract(yearsBefore, 'year').startOf('year')
export const dateMaxImportant = dayjs(process.env.REACT_APP_DATE_NOW).add(yearsAfter, 'year').endOf('year')

const getErrorMessage = (date) => {
  if (!date) return ''
  if (date < dateMinImportant) return 'Date antérieure au ' + dateMinImportant.format('DD/MM/YYYY')
  if (date > dateMaxImportant) return 'Date postérieure au ' + dateMaxImportant.format('DD/MM/YYYY')
  return ''
}

export default function DatePicker ({ label, value, onChange, required, disabled, sx }) {
  const [errorMessage, setErrorMessage] = useState(getErrorMessage(value))

  sx = sx || {}
  sx.minWidth = sx.minWidth || '200px'
  
  const handleOnChange = (newDate) => {
    onChange(newDate)
    setErrorMessage(getErrorMessage(newDate))
  }

  const ZZZZhandleKeyDown = (event) => {
    if (event.key === '/') {
      event.preventDefault()
    }
  }

  const ZZZZhandleKeyUp = (event) => {
    const input = event.target

    // Saisie d'un chiffre
    if (/^\d$/.test(event.key)) {
      if (/^\d\d$/.test(input.value) || /^\d\d\/\d\d$/.test(input.value)) {
        input.value += '/'
      }
    }

    // Correction
    if (/^Backspace$/i.test(event.key)) {
      if (/^\d\d$/.test(input.value) || /^\d\d\/\d\d$/.test(input.value)) {
        input.value = input.value.substring(0, input.value.length - 1)
      }
    }

    // Suppression des doubles slashes
    input.value = input.value.replace('//', '/')
  }

  return (
    <LocalizationProvider
      adapterLocale="fr"
      dateAdapter={ AdapterDayjs }
      localeText={ frFR.components.MuiLocalizationProvider.defaultProps.localeText }
    >
      <DesktopDatePicker
        label={ label }
        inputFormat="DD/MM/YYYY"
        minDate={ dateMinImportant }
        maxDate={ dateMaxImportant }
        views={ ['year', 'month', 'day'] }
        value={ value }
        disableMaskedInput={ true }
        onChange={ handleOnChange }
        renderInput={ (params) => (
          <TextField
            variant="filled"
            sx={ sx }
            required={ required }
            helperText={ errorMessage }
            { ...params }
          />
        )}
        disabled={ disabled }
        componentsProps={{
          actionBar: { actions: required ? ['today'] : ['clear', 'today'] }
        }}
      />
    </LocalizationProvider>
  )
}
