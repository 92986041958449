import { Box } from '@mui/material'
import useTheme from '../themes/material'
import LinkSiren from './LinkSiren'

export default function BoxClient ({ client }) {
  const theme = useTheme()
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <span style={{ fontWeight: 'bold', marginRight: theme.spacing(1) }}>
        { client.societe_nom }
      </span>
      { (client.forme_juridique || client.siren_numero) && (
        <>
          (
            { client.forme_juridique && (client.forme_juridique + ' - ') }
            { !!client.siren_numero && (
              <LinkSiren
                gestionNumero={ client.gestion }
                sirenNumero={ client.siren_numero }
              />
            ) }
          )
        </>
      ) }
      <br />
      <span style={{ fontSize: 'smaller', fontStyle: 'italic' }}>
        { client.adresse }
      </span>
    </Box>
  )
}
