import Headband from './Headband'
import WelcomeTitle from './WelcomeTitle'

export default function WelcomeAside ({ sxFlex }) {
  return (
    <Headband height="100vh" sx={{
      ...sxFlex,
      display: { xs: 'none', md: 'block' },
      flexGrow: 1
    }}>
      <WelcomeTitle />
    </Headband>
  )
}
