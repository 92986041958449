import { useContext } from 'react'
import { useNavigate } from "react-router-dom"
import { AppContext } from './context/AppContext'
import api from './lib/api'

export default function Logout () {
  const app = useContext(AppContext)
  const navigate = useNavigate()

  api.get('logout').then(response => {
    app.logout(() => {
      navigate('/connexion', { replace: true })
    })
  }).catch((error) => {
    app.snackbarError(error.message || 'Erreur lors de la déconnexion')
    navigate('/connexion', { replace: true })
  })
}
