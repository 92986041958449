import { useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Loader from './components/Loader'

export default function MotDePasseUpdate() {
  const app = useContext(AppContext)

  const navigate = useNavigate()
  const location = useLocation()

  let from = location.state?.from?.pathname
  from = !from || from === '/' ? '/accueil' : from

  useEffect(() => {
    api.get('update-password').then(response => {
      if (!response.data.resetPasswordUrl) {
        throw new Error("L'url de modification du mot de passe n'a pas été retournée par le serveur")
      }
      navigate(response.data.resetPasswordUrl)
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la demande de modification du mot de passe')
    })
  }, [])

  return (
    <Loader fullPage />
  )
}
