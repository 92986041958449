import React from 'react'
import { Container } from '@mui/material'
import useTheme from '../themes/material'

export default function MainContainer ({ sx, condensed, children }) {
  const theme = useTheme()
  const spacing = condensed ? theme.spacingResponsiveCondensed : theme.spacingResponsive

  sx = {
    ...sx,
    paddingTop: spacing,
    paddingBottom: spacing
  }

  return (
    <Container sx={ sx }>
      { children }
    </Container>
  )
}
