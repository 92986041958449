import { Fab } from '@mui/material'
import useTheme from '../themes/material'

export default function MyFab ({ icon, label, ariaLabel, onClick }) {
  const theme = useTheme()
  const sx = {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacingResponsive
  }

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        aria-label={ ariaLabel }
        sx={{
          ...sx,
          marginBottom: theme.spacing(.75),
          display: { xs: 'none', md: 'flex', gap: theme.spacing(1) }
        }}
        onClick={ onClick }
      >
        { icon }
        { label }
      </Fab>
      <Fab
        color="primary"
        aria-label={ ariaLabel }
        sx={{ ...sx, display: { xs: 'flex', md: 'none' } }}
        onClick={ onClick }
      >
        { icon }
      </Fab>
    </>
  )
}
