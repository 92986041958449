import { createContext, useState } from 'react'
import { useSnackbar } from 'notistack'
import { AppBar, Button, Dialog, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import useTheme from '../themes/material'

export const AppContext = createContext()

const AppProvider = ({ children }) => {
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [ready, setReady] = useState(false)
  const [greffe, setGreffe] = useState(null)
  const [user, setUser] = useState(null)
  const [userReplay, setUserReplay] = useState(null)
  const [client, setClient] = useState(null)
  const [colorMode, setColorMode] = useState('light')
  const [clientOptions, setClientOptions] = useState(null)
  const [auteurOptions, setAuteurOptions] = useState(null)
  const [popinOpen, setPopinOpen] = useState(false)
  const [popinTitle, setPopinTitle] = useState('')
  const [popinSrc, setPopinSrc] = useState('')

  const login = (user, callback) => {
    setUser(user)
    if (user.master) {
      setUserReplay(user)
    }
    setTimeout(callback, 100)
  }

  const handlePopinClose = () => {
    setPopinOpen(false)
  }

  const value = {
    ready: ready,
    greffe: greffe,
    user: user,
    userReplay: userReplay,
    client: client,
    colorMode: colorMode,
    clientOptions: clientOptions,
    auteurOptions: auteurOptions,
    toggleColorMode: () => {
      setColorMode(colorMode === 'dark' ? 'light' : 'dark')
    },
    init: (data, callback) => {
      setReady(true)
      window.document.title = 'GTC ' + (data?.greffe?.city || '') + ' : Espace client'
      setGreffe(data.greffe || null)
      setClientOptions(data.clientOptions || null)
      setAuteurOptions(data.auteurOptions || null)
      setClient(data.client || null)
      if (data.user) {
        login(data.user, callback)
        return
      }
      setTimeout(callback, 100)
    },
    login: login,
    logout: (callback) => {
      setUser(null)
      setUserReplay(null)
      setClient(null)
      setTimeout(callback, 100)
    },
    setClient: setClient,
    snackbar: enqueueSnackbar,
    closeSnackbar: closeSnackbar,
    snackbarError: (message) => (
      enqueueSnackbar(message, { variant: 'error' })
    ),
    snackbarWarning: (message) => (
      enqueueSnackbar(message, { variant: 'warning' })
    ),
    snackbarInfo: (message) => (
      enqueueSnackbar(message, { variant: 'info' })
    ),
    snackbarSuccess: (message) => (
      enqueueSnackbar(message, { variant: 'success' })
    ),
    popin: {
      title: popinTitle,
      src: popinSrc,
      open: (title, src) => {
        setPopinOpen(true)
        setPopinTitle(title)
        setPopinSrc(src)
      }
    }
  }

  return (
    <AppContext.Provider value={ value }>
      { children }
      <Dialog
        fullScreen
        open={ popinOpen }
        onClose={ handlePopinClose }
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              { popinTitle }
            </Typography>
            <Button
              endIcon={ <CloseIcon /> }
              onClick={ handlePopinClose }
              color="inherit"
              edge="end"
              aria-label="close"
              sx={{ marginRight: theme.spacing(-1) }}
            >
              Fermer
            </Button>
          </Toolbar>
        </AppBar>
        <embed
          src={ popinSrc }
          type="application/pdf"
          style={{ height: 'calc(100vh - 64px)' }}
        />
      </Dialog>
    </AppContext.Provider>
  )
}

export default AppProvider
