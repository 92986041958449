import React, { useContext } from 'react'
import MuiLink from '@mui/material/Link'
import { siren } from '../lib/format'
import { AppContext } from '../context/AppContext'

export default function LinkSiren ({ sirenNumero, gestionNumero }) {
  const app = useContext(AppContext)

  return (
    <MuiLink
      href={ getSirenHref(sirenNumero, gestionNumero) }
      target="_blank"
      rel="noreferrer"
    >{ siren(sirenNumero) }</MuiLink>
  )

  function getSirenHref (sirenNumero, gestionNumero) {
    return app.greffe.code === '7501' ?
      getEspaceCommandesUrl(sirenNumero, gestionNumero) :
      getInfogreffeUrl(sirenNumero)
  }

  function getEspaceCommandesUrl (sirenNumero, gestionNumero) {
    // https://commandes.greffe-tc-paris.fr/fr/societe/7501-2023D01543-949521959-cgoc.html
    // preg_match('/^societe\/(\d{4}-)?([\d\w]{10})-(\d{9})-.*\.html$/', $identifier, $matches, PREG_OFFSET_CAPTURE)

    return 'https://commandes.greffe-tc-paris.fr/fr/societe/' +
      encodeURIComponent(gestionNumero) + '-' +
      encodeURIComponent(sirenNumero) + '-.html'
  }

  function getInfogreffeUrl (sirenNumero) {
    return 'https://www.infogreffe.fr/entreprise-societe/' + sirenNumero + '.html'
  }
}
