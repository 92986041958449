import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import TableRow from './components/TableRow'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr from './lib/date'
import { euro } from './lib/format'
import ButtonPdf from './components/ButtonPdf'
import Link from './components/Link'

export default function RelevesDeFactures () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { id } = useParams()

  const dateMinDefault = cookies['relevesDeFactures-dateMin'] ? dayjs(cookies['relevesDeFactures-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['relevesDeFactures-dateMax'] ? dayjs(cookies['relevesDeFactures-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')
  const paiementEtatDefault = state?.paiementEtat

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [releveNumero, setReleveNumero] = useState(id || '')
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [paiementEtat, setPaiementEtat] = useState(paiementEtatDefault || 'all')
  const [submitCount, setSubmitCount] = useState(0)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'releve', label: 'N° relevé', sort: 'releve_numero' },
    { code: 'creation_date', label: 'Créé le', filter: dateFr, sort: 'creation_date' },
    { code: 'date_debut', label: 'Début', filter: dateFr, sort: 'date_debut' },
    { code: 'date_fin', label: 'Fin', filter: dateFr, sort: 'date_fin' },
    { code: 'total', label: 'Total', filter: euro, sort: 'total' },
    { code: 'relance', label: 'Relance', filter: dateFr, sort: 'relance' },
    { code: 'paye', label: 'Paiement', sort: 'montant_percu', sx: { color: theme.palette.success.main } },
    { code: 'factures', label: 'Factures', sort: 'nb_fac' }
  ]

  const formIsValid = () => {
    return releveNumero || (
      (dateMin.isValid() && dateMin >= dateMinImportant)
      &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const apiGetItems = () => {
    if (paiementEtatDefault !== 'nonSolde' && !formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)
    setPage(0)

    api.post('releves-de-factures', {
      'client_code': app.user.client_code,
      'releve_numero': (releveNumero || '').trim(),
      'date_min': (releveNumero || paiementEtatDefault === 'nonSolde' ? dateMinImportant : dateMin).format('DD/MM/YYYY'),
      'date_max': (releveNumero || paiementEtatDefault === 'nonSolde' ? dateMaxImportant : dateMax).format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des relevés de factures')
    })
  }

  const filteredItems = items === null ? null : items.filter(item => {
    if (!paiementEtat || paiementEtat === 'all') {
      return item
    } else if (paiementEtat === 'nonSolde' && item.montant_percu < item.total) {
      return item
    } else if (paiementEtat === 'solde' && item.montant_percu >= item.total) {
      return item
    }
    return false
  })

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('relevesDeFactures-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('relevesDeFactures-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitCount(submitCount + 1)
  }

  useEffect(() => {
    if (submitCount > 0) {
      apiGetItems()
    }
  }, [submitCount])

  useEffect(() => {
    setReleveNumero(id)
    setSubmitCount(submitCount + 1)
  }, [id, paiementEtatDefault])

  const renderTableRow = (item) => {
    const row = { ...item }

    const sxButton = {
      padding: theme.spacing(.75, 1.5),
      borderRadius: '64px'
    }

    row.releve = (
      <ButtonPdf
        numero={ row.releve_numero }
        name="releve-de-factures"
        label="Relevé de factures"
        ready={ row.index_ged_cle > 0 }
      />
    )

    row.paye = row.montant_percu ? (
      <>
        { euro(row.montant_percu) }
        <br />
        { 'le ' + dateFr(row.paiement_date) }
      </>
    ) : ''

    row.factures = (
      <Tooltip title="Afficher les factures du relevé">
        <Link to={ `/factures-du-releve/${row.releve_numero}` } tooltip="tooltip test">
          { row.nb_fac } factures
        </Link>
      </Tooltip>
    )

    return (
      <TableRow
        key={ row.releve_numero }
        row={ row }
        columns={ columns }
      />
    )
  }

  return (
    <Box>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={ (event) => handleSubmit(event) }>
          <Typography variant="h1">
            { !!id && ('Relevé de factures n°' + id) }
            { !id && ('Relevés de factures' + (paiementEtatDefault === 'nonSolde' ? ' non soldés' : '')) }
          </Typography>
          { !id && (
            <>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
                { paiementEtatDefault !== 'nonSolde' && (
                  <>
                    <DatePicker
                      label="Date de début"
                      value={ dateMin }
                      onChange={ handleSetDateMin }
                      disabled={ !!releveNumero }
                      required
                    />
                    <DatePicker
                      label="Date de fin"
                      value={ dateMax }
                      onChange={ handleSetDateMax }
                      disabled={ !!releveNumero }
                      required
                    />
                    <FormControl variant="filled">
                      <InputLabel id="paiementEtat-select-label">Relevés</InputLabel>
                      <Select
                        labelId="paiementEtat-select-label"
                        id="paiementEtat-select"
                        value={ paiementEtat }
                        onChange={ event => setPaiementEtat(event.target.value) }
                      >
                        <MenuItem value="all">Tous</MenuItem>
                        <MenuItem value="nonSolde">Non soldés</MenuItem>
                        <MenuItem value="solde">Soldés</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                ) }
              </Box>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
                <TextField
                  id="releveNumero"
                  label="N° de relevé"
                  variant="filled"
                  value={ releveNumero }
                  onChange={ (event) => {
                    setReleveNumero(event.target.value)
                  }}
                  InputProps={{
                    endAdornment: !!releveNumero && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Bouton pour vider le champ"
                          onClick={ () => {
                            setReleveNumero('')
                            document.getElementById('releveNumero').focus()
                          }}
                          edge="end"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <IconButton
                  type="submit"
                  aria-label="submit"
                  size="large"
                  sx={{ marginLeft: theme.spacing(1) }}
                  disabled={ !formIsValid() }
                >
                  <SearchIcon fontSize="large" />
                </IconButton>
              </Box>
            </>
          ) }
      </form>
      </Headband>
      <ListItems
        name="relevesDeFactures"
        items={ filteredItems }
        page={ page }
        setPage={ setPage }
        renderTableRow={ renderTableRow }
        columns={ columns }
      />
    </Box>
  )
}
