import React, { useContext } from 'react'

import Box from '@mui/material/Box'
import { Link as MuiLink, Avatar, Chip, FormControlLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from '@mui/material'
import Divider from '@mui/material/Divider'

import HomeIcon from '@mui/icons-material/HomeOutlined'
import LoginIcon from '@mui/icons-material/LoginOutlined'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import RssFeedIcon from '@mui/icons-material/RssFeedOutlined'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import SwapHorizIcon from '@mui/icons-material/SwapHorizOutlined'
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined'
import EuroIcon from '@mui/icons-material/EuroOutlined'
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined'
import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import CampaignIcon from '@mui/icons-material/CampaignOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import GavelIcon from '@mui/icons-material/GavelOutlined'
import BarChartIcon from '@mui/icons-material/BarChart'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import LogoutIcon from '@mui/icons-material/Logout'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'

import Link from './Link'
import { AppContext } from '../context/AppContext'
import { ColorModeContext } from '../context/ColorModeContext'
import Logo from './Logo'
import useTheme from '../themes/material'
import { useLocation, useNavigate } from 'react-router-dom'

export default function MainList ({ onNavigate }) {
  const app = useContext(AppContext)
  const { colorMode, toggleColorMode } = useContext(ColorModeContext)

  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme(colorMode)

  const handleClick = () => {
    if (typeof onNavigate === 'function') {
      onNavigate()
    }
  }

  const renderListItem = (label, href, icon) => (
    <ListItem
      disablePadding
      selected={ !href || location.pathname.indexOf(href) === -1 ? false : true }
      onClick={ handleClick }
    >
      <Link
        to={ href }
        sx={{ color: 'inherit', textDecoration: 'none', width: '100%' }}
      >
        <ListItemButton>
          { icon && <ListItemIcon>{ icon }</ListItemIcon> }
          <ListItemText primary={ label } />
        </ListItemButton>
      </Link>
    </ListItem>
  )

  return (
    <List sx={{ padding: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <MuiLink href={ app.greffe.url } sx={{ display: 'block', padding: theme.spacing(3) }}>
        <Logo />
      </MuiLink>
      <Divider />
      <Box
        sx={{
          margin: theme.spacing(2),
          display: 'flex',
          gap: theme.spacing(2),
          lineHeight: 1.1,
          color: theme.palette.primary.main,
          alignItems: 'center'
        }}
      >
        <Avatar sx={{ bgcolor: theme.palette.primary.main, width: 40, height: 40 }}>
          { app.user.firstname.substring(0, 1) }{ app.user.name.substring(0, 1) }
        </Avatar>
        <Box sx={{ flexGrow: 1 }}>
          <span style={{ fontWeight: 500 }}>{ app.user.firstname } { app.user.name }</span>
          <br />
          <small>
            { app.client ? app.client.code + ' - ' + app.client.label : 'Pas de client associé...' }
          </small>
        </Box>
      </Box>
      { renderListItem('Gestion du compte', 'gestion-du-compte', <PersonIcon />) }
      { renderListItem('Déconnexion', '/deconnexion', <LogoutIcon />) }
      <Divider />
      { renderListItem('Accueil', 'accueil', <HomeIcon />) }
      { app.client && app.client.code && app.client.id && (
        <>
          <Divider />
          { app.client?.avec_provision === 0 && renderListItem('Relevés de factures', '/releves-de-factures', <ReceiptIcon />) }
          { app.client?.avec_provision === 1 && renderListItem('Comptabilité', '/comptabilite', <SavingsOutlinedIcon />) }
          { renderListItem('Factures', '/factures', <EuroIcon />) }
          <Divider />
          { renderListItem('RCS : Formalités', '/rcs', <AccountBalanceIcon />) }
          { renderListItem('Actes et statuts', '/depots-actes-statuts', <AccountBalanceIcon />) }
          { renderListItem('Comptes annuels', '/depots-comptes-annuels', <CalculateIcon />) }
          <Divider />
          { renderListItem('BODACC : Avis provisoires', '/bodacc', <CampaignIcon />) }
          {/* renderListItem('Sûretés mobilières', '/suretes-mobilieres', <HomeWorkOutlinedIcon />) */}
          { renderListItem('Judiciaire', '/judiciaire', <GavelIcon />) }
          <Divider />
          { renderListItem('Statistiques', '/statistiques', <BarChartIcon />) }
        </>
      ) }
      <Box sx={{ flexGrow: 1, minHeight: theme.spacing(1.5) }}></Box>
      { !!app.user.admin && (
        <>
          <Divider>
            <Chip label="ADMINISTRATEUR" />
          </Divider>
          { app.userReplay && app.userReplay.id !== app.user.id && (
            <ListItem
              disablePadding
              onClick={ () => {
                navigate('/')
                app.login(app.userReplay)
              } }
            >
              <ListItemButton>
                <ListItemIcon>
                  <LoginIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={ app.userReplay.firstname + ' ' + app.userReplay.name }
                  sx={{ color: theme.palette.secondary.main }}
                />
              </ListItemButton>
            </ListItem>
          ) }
          { renderListItem('Gestion des utilisateurs', '/gestion-des-utilisateurs', <PeopleIcon />) }
          { renderListItem('Gestion des actualités', '/gestion-des-actualites', <RssFeedIcon />) }
          { renderListItem('Suivi de navigation', '/suivi-de-navigation', <QueryStatsIcon />) }
        </>
      ) }
      { !!app.user.master && (
        <>
          <Divider>
            <Chip label="MASTER" />
          </Divider>
          { renderListItem('Changement de greffe', '/changement-de-greffe', <SwapHorizIcon />) }
        </>
      ) }
      { process.env.NODE_ENV === 'development' && (
        <>
          <Divider sx={{ marginBottom: '0 !important' }} />
          <FormControlLabel
            control={<Switch checked={ colorMode === 'dark' } onChange={ toggleColorMode } />}
            label="Mode sombre"
            sx={{ padding: theme.spacing(2), display: 'flex', justifyContent: 'center' }}
          />
        </>
      ) }
    </List>
  )
}
