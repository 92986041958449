import React, { useContext, useState } from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'

import useTheme from '../themes/material'
import { AppContext } from '../context/AppContext'
import api from '../lib/api'

export default function DialogClient ({ open, setOpen }) {
  const app = useContext(AppContext)
  const theme = useTheme()

  const client = app.client || {
    code: ''
  }

  const [label, setLabel] = useState(client.label || '')
  const [caseCourrier, setCaseCourrier] = useState(client.case_courrier || 0)
  const [adresse, setAdresse] = useState(client.adresse || '')
  const [bureau, setBureau] = useState(client.bureau || '')
  const [email, setEmail] = useState(client.email || '')

  const isValidCaseCourrier = () => /^\d{1,2}$/.test(caseCourrier)

  const isValidBureau = () => bureau === '' || /^\d{10}$/.test(bureau)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!isValidCaseCourrier() || !isValidBureau()) {
      return
    }
    
    const updatedClient = {
      'label': label,
      'case_courrier': caseCourrier,
      'adresse': adresse,
      'bureau': bureau,
      'email': email
    }

    api.post('client/' + client.code, updatedClient).then(() => {
      setOpen(false)
      app.setClient({ ...app.client, ...updatedClient })
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification du compte client')
    })
  }

  const sxTextfield = {
    display: 'block',
    margin: theme.spacing(2, 0)
  }

  const renderField = (field, setField, label, required, autoFocus, disabled) => (
    <TextField
      label={ label }
      value={ field }
      onChange={ (event) => {
        setField(event.target.value)
      }}
      required={ required }
      autoFocus={ autoFocus }
      disabled={ disabled }
      variant="filled"
      fullWidth
      sx={ sxTextfield }
    />
  )

  return (
    <Dialog
      open={ open }
      onClose={ () => setOpen(false) }
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={ (event) => handleSubmit(event) }>
        <DialogTitle>Modification du compte client</DialogTitle>
        <DialogContent>
          { renderField(client.code, null, 'Code', true, false, true) }
          { renderField(label, setLabel, 'Nom du client', true) }
          <TextField
            label="Numéro de case courrier"
            value={ caseCourrier }
            onChange={ (event) => {
              setCaseCourrier(event.target.value)
            }}
            variant="filled"
            fullWidth
            sx={ sxTextfield }
            type="number"
            error={ !isValidCaseCourrier() }
            helperText={ isValidCaseCourrier() ? '' : 'Le numéro doit être compris entre 0 et 99' }
            inputProps={{ min: 0, max: 99 }}
          />
          { renderField(adresse, setAdresse, 'Adresse') }
          <TextField
            label="Numéro de téléphone"
            value={ bureau }
            onChange={ (event) => {
              setBureau(event.target.value)
            }}
            variant="filled"
            fullWidth
            sx={ sxTextfield }
            error={ !isValidBureau() }
            helperText={ isValidBureau() ? '' : 'Le numéro doit être composé de 10 chiffres' }
          />
          { renderField(email, setEmail, 'Email de facturation') }
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setOpen(false) }>Annuler</Button>
          <Button
            type="submit"
            disabled={ !isValidCaseCourrier() || !isValidBureau() }
          >
            Enregistrer
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
