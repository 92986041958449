import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Alert, Button, Paper, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import useTheme from './themes/material'
import { euro } from './lib/format'
import MainContainer from './components/MainContainer'

export default function GestionDuCompteClient () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const navigate = useNavigate()

  const client = app.client || {
    code: ''
  }

  const [label, setLabel] = useState(client.label || '')
  const [caseCourrier, setCaseCourrier] = useState(client.case_courrier || 0)
  const [adresse, setAdresse] = useState(client.adresse || '')
  const [bureau, setBureau] = useState(client.bureau || '')
  const [email, setEmail] = useState(client.email || '')

  const isValidCaseCourrier = () => /^\d{1,2}$/.test(caseCourrier)

  const isValidBureau = () => bureau === '' || /^\d{10}$/.test(bureau)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!isValidCaseCourrier() || !isValidBureau()) {
      return
    }
    
    const updatedClient = {
      'label': label,
      'case_courrier': caseCourrier,
      'adresse': adresse,
      'bureau': bureau,
      'email': email
    }

    api.post('client/' + client.code, updatedClient).then(response => {
      navigate(-1)
      app.setClient({ ...app.client, ...updatedClient })
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification du compte client')
    })
  }

  const sxTextfield = {
    display: 'block',
    margin: theme.spacing(2, 0)
  }

  const renderField = (field, setField, label, required, autoFocus, disabled) => (
    <TextField
      label={ label }
      value={ field }
      onChange={ (event) => {
        setField(event.target.value)
      }}
      required={ required }
      autoFocus={ autoFocus }
      disabled={ disabled }
      variant="filled"
      fullWidth
      sx={ sxTextfield }
    />
  )

  return (
    <Box>
      <Headband alignLeft>
      <Typography variant="h1">
          Gestion du compte client { client.code }
        </Typography>
        <Typography variant="h5" sx={{ margin: 0 }}>
          { label }
        </Typography>
      </Headband>
      <MainContainer>
        { !client.code && (
          <Alert severity="warning" sx={{ marginTop: theme.spacing(4) }}>
            Aucun compte client n'est associé à votre utilisateur
          </Alert>
        ) }
        { client.code && (
          <Box sx={{
            display: 'flex',
            gap: theme.spacingResponsive
          }}>
            <form style={{ minWidth: '480px', marginTop: theme.spacing(-2) }} onSubmit={ (event) => handleSubmit(event) }>
              { renderField(label, setLabel, 'Nom du client', true) }
              <TextField
                label="Numéro de case courrier"
                value={ caseCourrier }
                onChange={ (event) => {
                  setCaseCourrier(event.target.value)
                }}
                variant="filled"
                fullWidth
                sx={ sxTextfield }
                type="number"
                error={ !isValidCaseCourrier() }
                helperText={ isValidCaseCourrier() ? '' : 'Le numéro doit être compris entre 0 et 99' }
                inputProps={{ min: 0, max: 99 }}
              />
              { renderField(adresse, setAdresse, 'Adresse') }
              <TextField
                label="Numéro de téléphone"
                value={ bureau }
                onChange={ (event) => {
                  setBureau(event.target.value)
                }}
                variant="filled"
                fullWidth
                sx={ sxTextfield }
                error={ !isValidBureau() }
                helperText={ isValidBureau() ? '' : 'Le numéro doit être composé de 10 chiffres' }
              />
              { renderField(email, setEmail, 'Email de facturation') }
              <Box sx={{ margin: theme.spacing(4, 0) }}>
                <Button type="submit" variant="contained" disabled={ !isValidCaseCourrier() || !isValidBureau() }>Enregistrer</Button>
                <Button onClick={ () => navigate(-1) } sx={{ marginLeft: theme.spacing(2) }}>Annuler</Button>
              </Box>
            </form>
            <Box>
              { client.avec_provision == 0 && (
                <Paper elevation={ 0 } sx={{
                  padding: theme.spacing(5),
                  textAlign: 'center'
                }}>
                  <ReceiptIcon fontSize="large" />
                  <Typography>Client sans provision</Typography>
                  <Button
                    variant="outlined"
                    sx={{ marginTop: theme.spacing(3) }}
                    onClick={ () => navigate('/releves-de-factures', { state: { paiementEtat: 'nonSolde' } }) }
                  >Afficher les relevés de<br />factures non soldés</Button>
                </Paper>
              ) }
              { client.avec_provision == 1 && (
                <Alert
                  variant="filled"
                  severity={ client.solde > 500 ? 'success' : (client.solde > 0 ? 'warning' : 'error') }
                  icon={ false }
                  sx={{
                    padding: theme.spacing(4),
                    textAlign: 'center'
                  }}
                >
                  <SavingsOutlinedIcon fontSize="large" />
                  <Typography>Client avec provision</Typography>
                  <Typography variant="h5">Solde : { euro(client.solde) }</Typography>
                  <Button
                    variant="outlined"
                    color="white"
                    sx={{ marginTop: theme.spacing(3) }}
                  >Approvisionner le compte</Button>
                </Alert>
              ) }
            </Box>
          </Box>
        ) }
      </MainContainer>
    </Box>
  )
}
