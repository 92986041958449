import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

export default function MyTableRow ({ row, columns }) {
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:not(.no-count):nth-of-type(odd)': {
      backgroundColor: theme.palette.background.paper
    },
    '&:not(.no-count):hover, &.no-count': {
      backgroundColor: `${theme.palette.primary.real}11`
    }
  }))
  
  return (
    <StyledTableRow className={ row.noCount ? 'no-count' : null }>
      { columns.map((column) => {

        let content = row[column.code] || column.content || ''
        if (typeof column.filter === 'function') {
          content = column.filter(content)
        }

        return (
          <TableCell key={ column.code } sx={{ ...column.sx, ...row.sx }}>
            { content }
          </TableCell>
        )
      }) }
    </StyledTableRow>
  )
}
