import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

export default function useTheme (mode) {
  mode = mode || 'light'

  const backgroundColorDefault = mode === 'dark' ? '#121218' : '#f3f5f6'
  const backgroundColorPaper = mode === 'dark' ? '#1d1d24' : '#ffffff'
  const backgroundColorTextField = mode === 'dark' ? '#ffffff0d' : '#ffffff'
  const primaryColor = '#0d65ae'

  let theme = createTheme({
    typography: {
      /*fontFamily: 'Raleway, Arial'*/
    },
    palette: {
      mode,
      background: {
        default: backgroundColorDefault,
        paper: backgroundColorPaper
      },
      primary: {
        light: '#2d85ce',
        real: primaryColor,
        main: mode === 'dark' ? '#0080c1' : primaryColor,
        dark: '#00458e'
      },
      secondary: {
        light: '#ff6b6b',
        main: '#e94b4b',
        dark: '#c92b2b'
      },
      white: {
        main: '#ffffff'
      },
      gray: {
        light: '#f3f5f6',//'#f6f9fa',
        main: '#dddddd',
        dark: '#666666'
      }
    },
    breakpoints: {
      values: {
        xs: 0,   // Default 0
        sm: 560, // Default 600
        md: 880, // Default 900
        lg: 1200, // Default 1200
        xl: 1440 // Default 1536
      }
    },
    gap: {
      xs: 3,
      sm: 4,
      md: 5,
      lg: 6,
      xl: 8
    }
  })

  theme = createTheme(theme, {
    spacingResponsive: {
      xs: theme.spacing(3),
      sm: theme.spacing(4),
      md: theme.spacing(5),
      lg: theme.spacing(6),
      xl: theme.spacing(8)
    },
    spacingResponsiveCondensed: {
      xs: 0,
      sm: theme.spacing(1),
      md: theme.spacing(2),
      lg: theme.spacing(3),
      xl: theme.spacing(4)
    }
  })

  theme = responsiveFontSizes(createTheme(theme, {
    typography: {
      h1: {
        fontSize: '2.5rem',
        marginTop: 0,
        marginBottom: 0
      },
      h2: {
        fontSize: '2rem',
        margin: '0 0 1.5rem 0',
        color: theme.palette.primary.main
      },
      subtitle: {
        display: 'inline-block',
        fontSize: '1.2rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        color: theme.palette.primary.main
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: '90%'
          },
          '&*': {
            scrollbarWidth: 'thin'
          },
          '&::-webkit-scrollbar': {
            width: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.divider
          },
          '&blockquote': {
            borderLeft: '5px solid #ccc',
            fontStyle: 'italic',
            margin: theme.spacing(2, 0),
            padding: theme.spacing(.75, 3)
          },
          '&blockquote p': {
            margin: 0,
            padding: theme.spacing(.75, 0)
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: theme.gap.xs,
            paddingRight: theme.gap.xs,
            [theme.breakpoints.up('xs')]: {
              maxWidth: theme.breakpoints.values.lg,
              padding: theme.spacing(0, theme.gap.xs)
            },
            [theme.breakpoints.up('sm')]: {
              maxWidth: theme.breakpoints.values.lg,
              padding: theme.spacing(0, theme.gap.sm)
            },
            [theme.breakpoints.up('md')]: {
              maxWidth: theme.breakpoints.values.lg,
              padding: theme.spacing(0, theme.gap.md)
            },
            [theme.breakpoints.up('lg')]: {
              maxWidth: theme.breakpoints.values.lg,
              padding: theme.spacing(0, theme.gap.lg)
            },
            [theme.breakpoints.up('xl')]: {
              maxWidth: theme.breakpoints.values.xl,
              padding: theme.spacing(0, theme.gap.xl)
            },
            overflowX: 'auto'
          }
        }
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: '.75rem 0'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '.75rem 1rem'
          }
        }
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            '&::before, &::after': {
              top: 0
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '0',
            backgroundImage: 'none'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '12px 16px',
            borderRadius: 0,
            boxShadow: 'none'
          }
        }
      },
      /*MuiToggleButton: {
        styleOverrides: {
          root: {
            border: 'none',
            '&.Mui-selected': {
              'background': 'none'
            }
          }
        }
      },*/
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundColorTextField,
            '&:.Mui-focused': {
              backgroundColor: backgroundColorTextField,
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 0,
            fontSize: '100%',
            textAlign: 'center',
            '&:last-child': {
              paddingRight: theme.spacing(2)
            }
          },
          head: {
            fontWeight: 'normal',
            //fontSize: '.8rem',
            lineHeight: 1.5,
            padding: theme.spacing(2.5, 0, 2.5, 2)
          },
          body: {
            padding: theme.spacing(0, 0, 0, 2),
            height: '56px',
            whiteSpace: 'nowrap'       
          }
        }
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            padding: 0,
            borderTop: `1px solid ${theme.palette.divider}`,
            justifyContent: 'center',    
            backgroundColor: backgroundColorDefault
          },
          selectLabel: {
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          },
          displayedRows: {
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          },
          spacer: {
            [theme.breakpoints.down('sm')]: {
              display: 'none'
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: theme.palette.secondary.main,
              borderRight: `4px solid ${theme.palette.secondary.main}`,
              backgroundColor: `${theme.palette.secondary.main}11`,
              '& .MuiListItemIcon-root': {
                color: theme.palette.secondary.main,
              }
            }
          }
        }
      }
    }
  }))
  
  return theme
}
