import { Avatar, Card, CardContent, CardHeader, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from '@mui/material'
import IconDone from '@mui/icons-material/Done'
import { Box } from '@mui/system'

import IconPdf from './IconPdf'
import useTheme from '../themes/material'

export default function CardFacture ({ count, item }) {
  const theme = useTheme()
  const sxLabel = { fontSize: '.75rem', marginRight: '4px' }
  const sxValue = { fontWeight: '500' }
  const sxAvatar = {
    width: 24,
    height: 24,
    fontSize: '.75rem',
    marginRight: '24px',
    color: 'inherit',
    backgroundColor: '#00000011'
  }

  //item['N° relevé'] = 'AZERTY20221212'

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', padding: '32px' }}>
      <CardHeader
        action={
          <Tooltip title="Télécharger le PDF">
            <IconButton aria-label="settings">
              <IconPdf />
            </IconButton>
          </Tooltip>
        }
        title={ `${count}. Facture ${item['N° facture']}` }
        subheader={ <>
          <span style={{ marginRight: '12px' }}>Du { item['Date'] }</span>
          { !item['N° relevé'] && (
            <span style={{ textDecoration: 'line-through' }}>relevé</span>
          ) }
          { item['N° relevé'] && (
            <Chip size="small" label={ item['N° relevé'] } icon={ <IconDone /> } />
          ) }
          <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
            <div>
              <span style={ sxLabel }>HT :</span>
              <span style={ sxValue }>{ item.HT }</span>
            </div>
            <div>
              <span style={ sxLabel }>TVA :</span>
              <span style={ sxValue }>{ item.TVA }</span>
            </div>
            <div>
              <span style={ sxLabel }>TTC :</span>
              <span style={ sxValue }>{ item.TTC }</span>
            </div>
            <div>
              <span style={ sxLabel }>NT :</span>
              <span style={ sxValue }>{ item.NT }</span>
            </div>
            <div>
              <span style={ sxLabel }>Total :</span>
              <span style={ sxValue }>{ item.Total }</span>
            </div>
          </Box>
        </> }
      />
      <CardContent>
        <List sx={{ padding: 0 }}>
          { item.children && item.children.map((child, index) => (
            <Box key={ index }>
              { index > 0 && <Divider variant="inset" component="li" sx={{ marginLeft: '56px' }} /> }
              <ListItem sx={{ padding: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={ sxAvatar }>
                    { index + 1 }
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={ child.tarif_libelle } secondary={ child.article_libelle } />
              </ListItem>
            </Box>
          )) }
        </List>
      </CardContent>
    </Card>
  )
}
