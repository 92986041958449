import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'

import { FilledInput, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, Switch, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import AddIcon from '@mui/icons-material/AddOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import useTheme from './themes/material'
import api from './lib/api'
import dateFr from './lib/date'
import TableRowActualite from './components/TableRowActualite'
import Fab from './components/Fab'

export default function GestionDesActualites () {
  const app = useContext(AppContext)
  const theme = useTheme()
  const [cookies, setCookie] = useCookies()
  const navigate = useNavigate()

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const [seeInactives, setSeeInactives] = useState(cookies['actualites-seeInactives'] === 'true')

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'publishedAt', label: 'Publié le', filter: dateFr, sort: 'publishedAt' },
    { code: 'titreDescription', label: 'Actualité', sort: 'titre' },
    { code: 'auteurLabel', label: 'Auteur', sort: 'auteurLabel' },
    { code: 'activeSwitch', label: 'Actif', sort: 'active' },
    { code: 'activeDates', label: 'Dates d\'affichage', sort: 'activeStart' },
    { code: 'actions', label: '' }
  ]

  const getInformations = () => {
    setItems(null)
    setPage(0)

    api.get('informations').then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des actualités')
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    getInformations()
  }

  const handleSetSeeInactives = () => {
    setSeeInactives(!seeInactives)

    setCookie('actualites-seeInactives', !seeInactives, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  useEffect(getInformations, [])

  const sxTextfield = {
    width: '240px'
  }

  const filteredItems = items && items.filter(item => {
    if (!seeInactives && !item.active) {
      return false
    }

    if (search.trim() === '') return item 
    if (item.titre && item.titre.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    if (item.texte && item.texte.toLowerCase().indexOf(search.toLowerCase()) !== -1) return item
    return false
  })

  return (
    <Box>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={ (event) => handleSubmit(event) }>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <Typography variant="h1" sx={{ display: 'inline-block', margin: '0' }}>Gestion des actualités</Typography>
            {/* <Tooltip title="Ajouter une nouvelle actualité">
              <IconButton
                aria-label="Ajouter une nouvelle actualité"
                sx={{ borderWidth:'2px', borderStyle: 'solid' }}
                onClick={ () => navigate(editUrl) }
              >
                <AddIcon />
              </IconButton>
            </Tooltip> */}
          </Box>
          <Box sx={{ display: 'flex', gap: theme.spacing(4), alignItems: 'center' }}>
            <FormControl sx={ sxTextfield } variant="filled">
              <InputLabel htmlFor="search">Recherche générale</InputLabel>
              <FilledInput
                id="search"
                value={ search }
                onChange={ (event) => {
                  setSearch(event.target.value)
                }}
                endAdornment={
                  search && <InputAdornment position="end">
                    <IconButton
                      aria-label="Bouton pour vider le champ"
                      onClick={ () => {
                        setSearch('')
                        document.getElementById('search').focus()
                      }}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControlLabel
              control={<Switch checked={ seeInactives } onChange={ handleSetSeeInactives } color="secondary" />}
              label="Afficher les inactifs"
              sx={{ color: '#ffffff' }}
            />
          </Box>
        </form>
      </Headband>
      <ListItems
        name="informations"
        items={ filteredItems }
        page={ page }
        setPage={ setPage }
        renderTableRow={ (item) => <TableRowActualite key={ item.id } item={ item } columns={ columns } /> }
        columns={ columns }
      />
      <Fab
        icon={ <AddIcon /> }
        label="Ajouter une actualité"
        ariaLabel="add"
        onClick={ () => navigate('/gestion-des-actualites/edition') }
      />
    </Box>
  )
}
