import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useLocation, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import DeleteIcon from '@mui/icons-material/CloseOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import CardFacture from './components/CardFacture'
import TableRowFacture from './components/TableRowFacture'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr from './lib/date'
import { euro } from './lib/format'
import Link from './components/Link'
import ButtonPdf from './components/ButtonPdf'

const columns = [
  { code: 'count', label: '#', sort: 'count' },
  { code: 'code', label: 'N° facture', sort: 'facture_numero' },
  { code: 'facture_date', label: 'Date', filter: dateFr, sort: 'facture_date' },
  { code: 'releve', label: 'N° relevé', sort: 'releve_numero' },
  { code: 'facture_ht', label: 'HT', filter: euro, sort: 'facture_ht' },
  { code: 'facture_tva', label: 'TVA', filter: euro, sort: 'facture_tva' },
  { code: 'facture_ttc', label: 'TTC', filter: euro, sort: 'facture_ttc' },
  { code: 'facture_nt', label: 'Non taxé', filter: euro, sort: 'facture_nt' },
  { code: 'facture_total', label: 'Total', filter: euro, sort: 'facture_total', sx: { fontWeight: 'bold' } }
]

export default function Factures () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()
  const { id, releveNumero, paramDateMin, paramDateMax } = useParams()

  const dateMinDefault = paramDateMin ? dayjs(paramDateMin) : (
    cookies['factures-dateMin'] ?
    dayjs(cookies['factures-dateMin']) :
    dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  )

  const dateMaxDefault = paramDateMax ? dayjs(paramDateMax) : (
    cookies['factures-dateMax'] ?
    dayjs(cookies['factures-dateMax']) :
    dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')
  )

  const [items, setItems] = useState(null)
  const [page, setPage] = useState(0)
  const [factureNumero, setFactureNumero] = useState(id || '')
  const [factureDetail, setFactureDetail] = useState('')
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)
  const [submitCount, setSubmitCount] = useState(0)

  const formIsValid = () => {
    return factureNumero || (
      (dateMin.isValid() && dateMin >= dateMinImportant)
      &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const apiGetItems = () => {
    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)
    setPage(0)

    // Recherche contextuelle sur le numéro de facture 2022FA0017530
    let factureNumeroForce = ''
    if (factureNumero) {
      factureNumeroForce = factureNumero
    } else if (factureNumeroForce = MetierNormaliseNumeroFacture(factureDetail)) {
      setFactureDetail(factureNumeroForce)
    }

    api.post('factures', {
      'client_code': app.user.client_code,
      'releve_numero': (releveNumero || '').trim(),
      'facture_numero': (factureNumeroForce || '').trim(),
      'facture_detail': factureNumeroForce ? '' : encodeURIComponent(factureDetail.trim()),
      'date_min': (factureNumeroForce || releveNumero ? dateMinImportant : dateMin).format('DD/MM/YYYY'),
      'date_max': (factureNumeroForce || releveNumero ? dateMaxImportant : dateMax).format('DD/MM/YYYY')
    }).then(response => {
      setItems(response.data)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des factures')
    })
  }

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('factures-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict',
      // maxAge: 60 * 60 * 24 // 24h
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('factures-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitCount(submitCount + 1)
  }

  useEffect(() => {
    if (submitCount > 0) {
      apiGetItems()
    }
  }, [submitCount])

  useEffect(() => {
    setSubmitCount(submitCount + 1)
  }, [factureNumero, releveNumero])

  const renderCard = (item, count) => {
    return <CardFacture key={ count } count={ count } item={ item } />
  }

  const renderTableRow = (item) => {
    const row = { ...item }

    row.code = (
      <ButtonPdf
        numero={ row.facture_numero }
        name="facture"
        label="Facture"
        ready={ row.index_ged_cle > 0 }
      />
    )

    row.releve = !row.releve_numero ? null : (
      <Link to={ '/releve-de-factures/' + row.releve_numero }>
        { row.releve_numero }
      </Link>
    )
  
    return (
      <TableRowFacture
        key={ row.facture_numero }
        row={ row }
        columns={ columns }
      />
    )
  }

  return (
    <Box>
      <Headband alignLeft>
        <form style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }} onSubmit={ (event) => handleSubmit(event) }>
          <Typography variant="h1">
            { !!id && ('Facture n°' + id) }
            { !!paramDateMin && !!paramDateMax && ('Factures du ' + dateMin.format('DD/MM/YYYY') + ' au ' + dateMax.format('DD/MM/YYYY')) }
            { !id && !paramDateMin && !paramDateMax && ('Factures' + (!releveNumero ? '' : (' du relevé ' + releveNumero))) }
          </Typography>
          {/* <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
            <FormControl variant="filled">
              <InputLabel htmlFor="factureNumero">Numéro de facture</InputLabel>
              <FilledInput
                id="factureNumero"
                value={ factureNumero }
                onChange={ (event) => {
                  setFactureNumero(event.target.value)
                }}
                endAdornment={
                  factureNumero && <InputAdornment position="end">
                    <IconButton
                      aria-label="Bouton pour vider le champ"
                      onClick={ () => {
                        setFactureNumero('')
                        document.getElementById('factureNumero').focus()
                        //apiGetItems() // factureNumero n'est pas a jour dans apiGetItems... A voir.
                      }}
                      edge="end"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box> */}
          { !id && (
            <Box sx={{ display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}>
              { !releveNumero && !paramDateMin && !paramDateMax && (
                <>
                  <DatePicker
                    label="Date de début"
                    value={ dateMin }
                    onChange={ handleSetDateMin }
                    disabled={ !!factureNumero }
                  />
                  <DatePicker
                    label="Date de fin"
                    value={ dateMax }
                    onChange={ handleSetDateMax }
                    disabled={ !!factureNumero }
                  />
                </>
              ) }
              <FormControl variant="filled">
                <InputLabel htmlFor="factureNumero">Contenu de la facture</InputLabel>
                <FilledInput
                  id="factureDetail"
                  value={ factureDetail }
                  disabled={ !!factureNumero }
                  onChange={ (event) => {
                    setFactureDetail(event.target.value)
                  }}
                  endAdornment={
                    factureDetail && <InputAdornment position="end">
                      <IconButton
                        aria-label="Bouton pour vider le champ"
                        onClick={ () => {
                          setFactureDetail('')
                          document.getElementById('factureDetail').focus()
                        }}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <IconButton
                type="submit"
                aria-label="submit"
                size="large"
                sx={{ marginLeft: theme.spacing(1) }}
                disabled={ !formIsValid() }
              >
                <SearchIcon fontSize="large" />
              </IconButton>
            </Box>
          ) }
          {/*<Box sx={{ display: 'flex' }}>
          <Typography sx={{ color: '#ffffff', marginTop: '1rem', fontStyle: 'italic' }}>
            * Seules les factures comprises entre le { dateMinImportant.format('DD/MM/YYYY') }&nbsp;
            et le { dateMaxImportant.format('DD/MM/YYYY') } sont consultables.
          </Typography>
            </Box>*/}
        </form>
      </Headband>
      <ListItems
        name="factures"
        items={ items }
        page={ page }
        setPage={ setPage }
        renderCard={ renderCard }
        renderTableRow={ renderTableRow }
        columns={ columns }
      />
    </Box>
  )
}

function MetierNormaliseNumeroFacture (facture) {
  /*if (/^\d{2}FA\d{1,7}$/.test(factureNumero)) {
    return '20' + factureNumero.substring(0, 4) + 
  }*/

  if (!facture || typeof facture !== 'string') {
    return ''
  }

  facture = facture.trim().toUpperCase()

  let pos = facture.indexOf('FA')
  if (pos < 0) pos = facture.indexOf('IN')
  if (pos < 0) pos = facture.indexOf('TP')
  if (pos < 0) pos = facture.indexOf('AV')
  if (pos < 0) pos = facture.indexOf('WE')
  if (pos < 0) pos = facture.indexOf('CI')
  if (pos < 0) pos = facture.indexOf('RD')
  if (pos < 0) pos = facture.indexOf('EC')

  if (pos !== 2 && pos !== 4) {
    return ''
  }

  if (pos === 2) {
    facture = (facture.substr(0, 1) < '5' ? '20' : '19') + facture
  }

  while (facture.length < 13) {
    facture = facture.substr(0, 6) + '0' + facture.substr(6)
  }

  if (facture.length !== 13 || facture.match(/\d{4}[FITAWCRPE][ANPVEIDC]\d{7}/) === null) {
    return ''
  }

  return facture
}
