import { useCookies } from 'react-cookie'

import { Box, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import ViewStreamIcon from '@mui/icons-material/ViewStreamOutlined'
import ViewTableIcon from '@mui/icons-material/TableViewOutlined'

import useTheme from '../themes/material'

export default function Pagination (props) {
  const theme = useTheme()
  const [cookies, setCookie, removeCookie] = useCookies()

  const name = props.name
  const setView = props.setView

  const tablePaginationProps = { ...props }
  delete tablePaginationProps.name
  delete tablePaginationProps.setView

  const myLabelDisplayedRows = ({from, to, count}) => (
    `${from}–${to} sur ${count !== -1 ? count : `plus de ${to}`}`
  )
  
  const handleView = (event) => {
    setView(event.currentTarget.value)

    setCookie(name + '-view', event.currentTarget.value, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

        /* <ToggleButtonGroup
        value={ props.view }
        exclusive
        onChange={ handleView }
        aria-label="Vue"
      >
        <ToggleButton value="stream" aria-label="Vue fiche">
          <Tooltip title="Vue fiche">
            <ViewStreamIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="table" aria-label="Vue liste">
          <Tooltip title="Vue liste">
            <ViewTableIcon />
          </Tooltip>
        </ToggleButton>
  </ToggleButtonGroup> */

  return (
    <TablePagination
      { ...tablePaginationProps }
      rowsPerPageOptions={ [10, 20, 50] }
      component="div"
      labelRowsPerPage="Lignes par page"
      labelDisplayedRows={ myLabelDisplayedRows }
      showFirstButton
      showLastButton
      sx={{
        width: { 'xs': '100%', 'lg': 'calc(100% - 276px)' },
        left: { 'xs': '0', 'lg': '276px' },
        gap: { 'xs': '1rem', 'md': '2rem' },
        flexDirection: { 'xs': 'column', 'sm': 'row' }
      }}
    />
  )
}

function getWindowSize () {
  const { innerWidth, innerHeight } = window
  return { innerWidth, innerHeight }
}
