import { createContext, useState } from 'react'
import { useCookies } from 'react-cookie'

export const ColorModeContext = createContext()

const ColorModeContextProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const [colorMode, setColorMode] = useState(cookies['colorMode'] || 'light')
  const toggleColorMode = () => {
    const newColorMode = colorMode === 'dark' ? 'light' : 'dark'
    setColorMode(newColorMode)
    setCookie('colorMode', newColorMode, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  return (
    <ColorModeContext.Provider value={{ colorMode, toggleColorMode }}>
      { children }
    </ColorModeContext.Provider>
  )
}

export default ColorModeContextProvider
