import { Table, TableHead, TableBody, TableRow, TableCell, Skeleton } from '@mui/material'
import MainContainer from './MainContainer'

export default function TableSkeleton ({ columns }) {
  return (
    <MainContainer condensed >
      <Table>
        <TableHead>
          <TableRow>
            { columns.map((column, index) => (
              <TableCell key={ index } sx={ column.code === 'pdf' ? { padding: 0 } : null }>
                { column.label }
              </TableCell>
            )) }
          </TableRow>
        </TableHead>
        <TableBody>
          { function () {
            const lines = []
            for (let i = 0; i < 10; i++) {
              lines.push(
                <TableRow key={ i }>
                  { columns.map((column, index) => (
                    <TableCell key={ index }>
                      <Skeleton variant="text" height={ 40 }/>
                    </TableCell>
                  )) }
                </TableRow>
              )
            }
            return lines
          }() }
        </TableBody>
      </Table>
    </MainContainer>
  )
}
