import React, { useState } from 'react'
import { useCookies } from 'react-cookie'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import Pagination from './Pagination'
import AlertNoResult from './AlertNoResult'
import TableSkeleton from './TableSkeleton'
import MainContainer from './MainContainer'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import IconSort from './IconSort'

export default function ListItems ({ name, items, view, setView, page, setPage, renderTableRow, columns, children }) {
  const [cookies, setCookie] = useCookies()

  const [rowsPerPage, setRowsPerPage] = useState(cookies[name + '-rowsPerPage'] * 1 || 10)
  const [sortColumn, setSortColumn] = useState(columns[0].sort === 'count' ? 'count' : '')
  const [sortDirection, setSortDirection] = useState(columns[0].sort === 'count' ? 'ASC' : '')

  const sxNoPadding = { padding: '0' }

  if (items === null) {
    return <TableSkeleton columns={ columns } />
  } else if (typeof items !== 'object' || items.length === 0) {
    return <AlertNoResult />
  }

  let count = 0
  items.map(item => {
    if (!item.noCount) {
      item.count = ++count
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)

    setCookie(name + '-rowsPerPage', event.target.value, {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const sortedItems = [].concat(items).sort((a, b) => {
    if (a.noCount || b.noCount) {
      // Ligne d'entête en premier
      // Ligne de pied en dernier
      return 0
    } else if (sortColumn === '' || sortDirection === '' || a[sortColumn] === b[sortColumn]) {
      return 0
    } else if (a[sortColumn] > b[sortColumn]) {
      return (sortDirection === 'ASC' ? 1 : -1)
    }
    return (sortDirection === 'ASC' ? -1 : 1)
  })

  const filteredRows = page === false ? sortedItems : sortedItems.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  const sort = function (column_code) {
    let newColumn = column_code
    let newDirection = 'ASC'

    if (newColumn === sortColumn && sortDirection === 'ASC') {
      newDirection = 'DESC'
    } else if (newColumn === sortColumn && sortDirection === 'DESC') {
      newColumn = ''
      newDirection = ''      
    }

    setSortColumn(newColumn)
    setSortDirection(newDirection)
    setPage(0)
  }

  return (
    <>
      <MainContainer condensed sx={{ mb: '52px' }}>
        <Table
          id="list-items"
          sx={{ minWidth: 650 }}
          aria-label="Table principale"
        >
          <TableHead sx={{ position: 'sticky', top: 0 }}>
            <TableRow>
              { columns.map((column) => (
                <TableCell
                  key={ column.code }
                  component="th"
                  align="center"
                  sx={ column.code === 'pdf' ? sxNoPadding : null }
                >
                  { !!column.sort && (
                    <Tooltip title="Trier">
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                        onClick={ () => {
                          sort(column.sort)
                        } }
                      >
                        <span>{ column.label }</span>
                        {
                          column.sort === sortColumn && sortDirection !== '' ? (
                            sortDirection === 'ASC' ?
                            <ArrowDropUp fontSize="small" /> :
                            <ArrowDropDown fontSize="small" />
                          ) : <IconSort fontSize="small" />
                        }
                      </Box>
                    </Tooltip>
                  ) }
                  { !column.sort && column.label }
                </TableCell>
              )) }
            </TableRow>
          </TableHead>
          <TableBody>
            { filteredRows && filteredRows.map(row => {
              return renderTableRow(row)
            }) }
          </TableBody>
        </Table>
        { children || null }
      </MainContainer>
      { page !== false && (
        <Pagination
          name={ name }
          count={ items.length }
          rowsPerPage={ rowsPerPage }
          page={ page }
          onPageChange={ handleChangePage }
          onRowsPerPageChange={ handleChangeRowsPerPage }
          view={ view }
          setView={ setView }
        />
      ) }
    </>
  )
}
