import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNavigate, useRouteError } from 'react-router-dom'

export default function ErrorPage () {
  const navigate = useNavigate()
  const error = useRouteError()

  console.error(error)

  const handleClick = () => {
    navigate('/accueil')
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '480px', textAlign: 'center' }}>
        <Typography variant="h2">
          { error.status === 404 && <>
            <span style={{ fontSize: '8rem', fontWeight: '300' }}>404</span>
            <br />
            Page inexistante
          </> }
          { (!error.status || error.status !== 404) && <>
            <span style={{ fontSize: '8rem', fontWeight: '300', lineHeight: 1 }}>:(</span>
            <br /><br />
            Erreur... { error.statusText || error.message || 'Erreur indéfinie...' }
          </> }
        </Typography>
        <Button size="large" variant="outlined" onClick={ handleClick }>
          Retour à l'accueil
        </Button>
      </Box>
    </Box>
  )
}
