import React, { useContext } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  Navigate,
  Outlet
} from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import Connexion from './Connexion'
import MotDePasseReset from './MotDePasseReset'
import MotDePasseUpdate from './MotDePasseUpdate'
import Deconnexion from './Deconnexion'
import Accueil from './Accueil'
import GestionDuCompte from './GestionDuCompte'
import GestionDuCompteClient from './GestionDuCompteClient'
import RelevesDeFactures from './RelevesDeFactures'
import Factures from './Factures'
import Comptabilite from './Comptabilite'
import Rcs from './Rcs'
import DepotsComptesAnnuels from './DepotsComptesAnnuels'
import DepotsActesStatuts from './DepotsActesStatuts'
import Bodacc from './Bodacc'
import Judiciaire from './Judiciaire'
import Statistiques from './Statistiques'
import SuiviDeNavigation from './SuiviDeNavigation'
import GestionDesUtilisateurs from './GestionDesUtilisateurs'
import EditionUtilisateur from './EditionUtilisateur'
import GestionDesActualites from './GestionDesActualites'
import EditionActualite from './EditionActualite'
import ChangementDeGreffe from './ChangementDeGreffe'
import ErrorPage from './ErrorPage'

import Layout from './components/Layout'
import Loader from './components/Loader'
import AppProvider, { AppContext } from './context/AppContext'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import './styles/globals.css'
import api from './lib/api'

function App () {
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const router = createBrowserRouter([
    {
      path: '/connexion',
      element: <RequireGreffe><Connexion /></RequireGreffe>
    },
    {
      path: '/mot-de-passe-oublie',
      element: <RequireGreffe><Connexion forgottenPassword /></RequireGreffe>
    },
    {
      path: '/mot-de-passe-reset/:userId/:token',
      element: <RequireGreffe><MotDePasseReset /></RequireGreffe>
    },
    {
      path: '/mot-de-passe-reset/:userId/:token/:username',
      element: <RequireGreffe><MotDePasseReset /></RequireGreffe>
    },
    {
      path: '/',
      element: <RequireUser><Layout /></RequireUser>,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <Accueil />
        },
        {
          path: 'accueil',
          element: <Accueil />
        },
        {
          path: 'mes-informations-personnelles',
          element: <EditionUtilisateur />
        },
        {
          path: 'gestion-du-compte',
          element: <GestionDuCompte />
        },
        {
            path: 'mot-de-passe-update',
            element: <MotDePasseUpdate />
        },
        {
          path: 'deconnexion',
          element: <Deconnexion />
        },
        {
          path: 'gestion-du-compte-client',
          element: <GestionDuCompteClient />
        },
        {
          path: 'releves-de-factures',
          element: <RelevesDeFactures />
        },
        {
          path: 'releve-de-factures/:id',
          element: <RelevesDeFactures />
        },
        {
          path: 'factures/:paramDateMin/:paramDateMax',
          element: <Factures />
        },
        {
          path: 'factures-du-releve/:releveNumero',
          element: <Factures />
        },
        {
          path: 'factures',
          element: <Factures />
        },
        {
          path: 'facture/:id',
          element: <Factures />
        },
        {
          path: 'comptabilite',
          element: <Comptabilite />
        },
        {
          path: 'rcs',
          element: <Rcs />
        },
        {
          path: 'rcs/:paramDateMin/:paramDateMax',
          element: <Rcs />
        },
        {
          path: 'depots-comptes-annuels',
          element: <DepotsComptesAnnuels />
        },
        {
          path: 'depots-actes-statuts',
          element: <DepotsActesStatuts />
        },
        {
          path: 'bodacc',
          element: <Bodacc />
        },
        {
          path: 'suretes-mobilieres',
          element: <Bodacc />
        },
        {
          path: 'judiciaire',
          element: <Judiciaire />
        },
        {
          path: 'statistiques',
          element: <Statistiques />
        },
        {
          path: 'gestion-des-utilisateurs',
          element: <RequireAdmin><Outlet /></RequireAdmin>,
          children: [
            {
              path: '',
              element: <GestionDesUtilisateurs />
            },
            {
              path: 'edition',
              element: <EditionUtilisateur />
            }
          ]
        },
        {
          path: 'gestion-des-actualites',
          element: <RequireAdmin><Outlet /></RequireAdmin>,
          children: [
            {
              path: '',
              element: <GestionDesActualites />
            },
            {
              path: 'edition',
              element: <EditionActualite />
            }
          ]
        },
        {
          path: 'suivi-de-navigation',
          element: <RequireAdmin><SuiviDeNavigation /></RequireAdmin>
        },
        {
          path: 'changement-de-greffe',
          element: <RequireReplay><ChangementDeGreffe /></RequireReplay>
        },
      ]
    },
  ])

  return (
    <ThemeProvider theme={ theme }>
      <SnackbarProvider maxSnack={ 4 }>
        <AppProvider>
          <CssBaseline />
          <RouterProvider router={ router } />
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

function apiGetAuth (app) {
  api.get('auth').then(response => {
    app.init(response.data)
  }).catch(error => {
    app.init({ greffe: { code: '0000' }})
    app.snackbarError(error.message || 'Erreur de connexion à l\'API')
  })
}

function RequireGreffe ({ children }) {
  const app = useContext(AppContext)

  if (app.greffe) {
    return children
  }

  apiGetAuth(app)

  return <Loader fullPage />
}

function RequireUser ({ children }) {
  const app = useContext(AppContext)
  const location = useLocation()

  if (app.user) {
    return children
  }

  if (app.ready) {
    return <Navigate to='/connexion' state={{ from: location }} replace />
  }

  apiGetAuth(app)

  return <Loader fullPage />
}

function RequireAdmin ({ children }) {
  const app = useContext(AppContext)

  if (!app.user || !app.user.admin) {
    throw new Response('Not Found', { status: 404 })
  }

  return children
}

function RequireReplay ({ children }) {
  const app = useContext(AppContext)

  if (!app.user || !app.user.master) {
    throw new Response('Not Found', { status: 404 })
  }

  return children
}

export default App
