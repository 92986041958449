import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box } from '@mui/system'
import { Chip, IconButton, Switch, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/EditOutlined'

import { AppContext } from './../context/AppContext'
import api from './../lib/api'
import dateFr from './../lib/date'
import dayjs from 'dayjs'
import MyTableRow from './TableRow'
import useTheme from '../themes/material'

const editUrl = '/gestion-des-actualites/edition'
const dateNow = dayjs(process.env.REACT_APP_DATE_NOW)

export default function TableRowActualite ({ item, columns }) {
  const app = useContext(AppContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const [active, setActive] = useState(item.active)

  const row = { ...item }

  const sxFlex = { display: 'inline-flex', alignItems: 'center', gap: theme.spacing(1) }

  row.data = item

  const handleActiveSwitch = () => {
    setActive(!active)
    api.post('information/' + item.id, { active: !active }).then(response => {
      app.snackbarSuccess('État de l\'actualité modifié')
    }).catch(error => {
      app.snackbarError(error.message || 'Erreur lors de la modification de l\'état de l\'actualité')
    })
  }

  let titre = row.titre || ''
  if (titre.length > 60) {
    titre = row.titre.substr(0, 57) + '...'
  }

  row.titreDescription = (
    <>
      <span style={{ fontWeight: 'bold' }}>{ titre }</span>
      <Typography variant="body2">
        Créé le { dateFr(row.createdAt) } - Modifié le { dateFr(row.updatedAt) }
      </Typography>
    </>
  )

  const activeStart = !row.activeStart || !dayjs(row.activeStart).isValid ? null : dayjs(row.activeStart)
  const activeEnd = !row.activeEnd || !dayjs(row.activeEnd).isValid ? null : dayjs(row.activeEnd)

  row.activeSwitch = (
    <Switch checked={ !!active } onChange={ handleActiveSwitch } />
  )

  const swChipDate = { minWidth: '88px' }

  row.activeDates = (
    <Box style={ sxFlex }>
      <Chip
        size="small"
        color={ activeStart ? (activeStart <= dateNow ? 'success' : 'error') : 'default' }
        variant="outlined"
        label={ dateFr(activeStart, '--/--/----') }
        disabled={ !active }
        sx={ swChipDate }
      />
      <Chip
        size="small"
        color={ activeEnd ? (dateNow <= activeEnd ? 'success' : 'error') : 'default' }
        variant="outlined"
        label={ dateFr(activeEnd, '--/--/----') }
        disabled={ !active }
        sx={ swChipDate }
      />
    </Box>
  )

  row.actions = (
    <>
      <Tooltip title="Modifier l'actualité">
        <IconButton onClick={ () => { navigate(editUrl, { state: item }) } }>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </>
  )

  return (
    <MyTableRow
      row={ row }
      columns={ columns }
    />
  )
}
