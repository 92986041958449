import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import dayjs from 'dayjs'

import { Button, IconButton, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/SearchOutlined'
import Box from '@mui/material/Box'

import { AppContext } from './context/AppContext'
import api from './lib/api'
import Headband from './components/Headband'
import ListItems from './components/ListItems'
import TableRow from './components/TableRow'
import { ColorModeContext } from './context/ColorModeContext'
import useTheme from './themes/material'
import DatePicker, { dateMinImportant, dateMaxImportant } from './components/DatePicker'
import dateFr, { dateTimeFr } from './lib/date'
import { euroOrEmpty, euroOrEmptyWithColor } from './lib/format'
import Link from './components/Link'

export default function Comptabilite () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [cookies, setCookie] = useCookies()
  
  const dateMinDefault = cookies['comptabilite-dateMin'] ? dayjs(cookies['comptabilite-dateMin']) : dayjs(process.env.REACT_APP_DATE_NOW).startOf('month')
  const dateMaxDefault = cookies['comptabilite-dateMax'] ? dayjs(cookies['comptabilite-dateMax']) : dayjs(process.env.REACT_APP_DATE_NOW).endOf('month')

  const [items, setItems] = useState(null)
  const [dateMin, setDateMin] = useState(dateMinDefault)
  const [dateMax, setDateMax] = useState(dateMaxDefault)

  const columns = [
    { code: 'count', label: '#', sort: 'count' },
    { code: 'date_heure_or_date', label: 'Date', sort: 'date_heure' },
    { code: 'type_label', label: 'Type', sort: 'type' },
    { code: 'complement', label: 'Complément', sx: { textAlign: 'left' } },
    { code: 'debit', label: 'Débit', sort: 'debit', filter: euroOrEmpty },
    { code: 'credit', label: 'Crédit', sort: 'credit', filter: euroOrEmpty },
    { code: 'solde_with_color', label: 'Solde', sort: 'solde', sx: {
      paddingRight: theme.spacing(2),
      borderRightWidth: '1px',
      borderRightStyle: 'solid',
      borderRightColor: theme.palette.divider,
      fontWeight: 'bold'
    } },
    { code: 'bodacc', label: 'BODACC', filter: euroOrEmpty, sort: 'bodacc' },
    { code: 'inpi', label: 'INPI', filter: euroOrEmpty, sort: 'inpi' },
    { code: 'debexo', label: 'Deb. Exo', filter: euroOrEmpty, sort: 'debexo' }
  ]

  const formIsValid = () => {
    return (
      (dateMin.isValid() && dateMin >= dateMinImportant)
      &&
      (dateMax.isValid() && dateMax <= dateMaxImportant)
    )
  }

  const sxHF = {
    fontStyle: 'italic'
  }

  const getComptabilite = () => {
    if (!formIsValid()) {
      app.snackbarError('Veuillez vérifier les filtres de recherche')
      return
    }

    setItems(null)

    api.post('comptabilite', {
      'client_code': app.user.client_code,
      'date_min': dateMin.format('DD/MM/YYYY'),
      'date_max': dateMax.format('DD/MM/YYYY')
    }).then(response => {
      const rows = []
      const soldeInitial = response.data.soldeInitial * 1
      const soldeFinal = response.data.soldeFinal * 1

      let debit = 0
      let credit = 0
      let solde = soldeInitial
      let bodacc = 0
      let inpi = 0
      let debexo = 0

      // Entête
      rows.push({
        cle_rang: 'thead',
        noCount: true,
        date_heure: dateMin,
        solde: solde,
        sx: sxHF
      })

      // Lignes
      response.data.items.map(item => {
        if (item.type === 'FA') {
          item.debit = -1 * item.montant_percu
          item.credit = 0
          debit += item.debit
        } else {
          item.debit = 0
          item.credit = 1 * item.montant_percu
          credit += item.credit
        }
        solde += 1 * item.montant_percu
        bodacc += 1 * item.bodacc
        inpi += 1 * item.inpi
        debexo += 1 * item.debexo

        item.solde = solde

        rows.push(item)

        return item
      })

      // Pied
      rows.push({
        cle_rang: 'tfoot',
        noCount: true,
        date_heure: dateMax,
        debit,
        credit,
        solde,
        bodacc,
        inpi,
        debexo,
        sx: sxHF
      })

      // Indication en cas d'écart sur le solde final (debug uniquement ?)
      const ecartCentimes = 100 * Math.abs(soldeFinal - solde)
      if (ecartCentimes >= .5) {
        const errorMessage = 'Écart de ' + ecartCentimes + ' centimes sur le solde final'
        console.error(errorMessage + ' (solde final SQL : '+ soldeFinal +' / solde final calculé : ' + solde + ')')
        app.snackbarError(errorMessage)
      }

      setItems(rows)
    }).catch(error => {
      setItems([])
      app.snackbarError(error.message || 'Erreur lors de la récupération des données comptables')
    })
  }

  const handleSetDateMin = (newDate) => {
    setDateMin(newDate)

    setCookie('comptabilite-dateMin', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }

  const handleSetDateMax = (newDate) => {
    setDateMax(newDate)

    setCookie('comptabilite-dateMax', newDate.format('YYYY-MM-DD'), {
      path: '/',
      secure: true,
      sameSite: 'strict'
    })
  }
  
  const handleSubmit = (event) => {
    event.preventDefault()
    getComptabilite()
  }

  useEffect(getComptabilite, [])

  const renderTableRow = (item) => {
    const row = { ...item }

    row.date_heure_or_date = row.noCount ?
      dateFr(row.date_heure) :
      dateTimeFr(row.date_heure)

    // type_label
    if (row.type === 'FA') {
      row.type_label = 'Facture'
    } else if (row.type === 'AV') {
      row.type_label = 'Avoir'
    } else if (row.type === 'CR') {
      row.type_label = 'Crédit'
    }

    // complement
    if (row.type === 'CR') {
      row.complement = row.libelle +  ' (' + row.mode_reglement + ')'
    } else {
      row.complement = (
        <Link to={ '/facture/' + row.numero }>
          { row.numero }
        </Link>
      )
      if (row.releve_numero) {
        row.complement = (
          <>
            { row.complement }
            { ' / ' }
            <Link to={ '/releve-de-facture/' + row.releve_numero }>
              { row.releve_numero }
            </Link>
          </>
        )
      }
    }

    // Affichage des montants en euros
    row.solde_with_color = euroOrEmptyWithColor(
      row.solde,
      theme.palette.error.main,
      theme.palette.success.main
    )

    return (
      <TableRow
        key={ row.cle_rang }
        row={ row }
        columns={ columns }
      />
    )
  }

  return (
    <Box>
      <Headband alignLeft sx={{ displayPrint: 'none' }}>
        <form style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2)
        }} onSubmit={ (event) => handleSubmit(event) }>
          <Typography variant="h1">Comptabilité</Typography>
          <Box sx={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center'
          }}>
            <DatePicker
              label="Date de début"
              value={ dateMin }
              onChange={ handleSetDateMin }
            />
            <DatePicker
              label="Date de fin"
              value={ dateMax }
              onChange={ handleSetDateMax }
            />
            <IconButton
              type="submit"
              aria-label="submit"
              size="large"
              sx={{ marginLeft: theme.spacing(1) }}
            >
              <SearchIcon fontSize="large" />
            </IconButton>
          </Box>
      </form>
      </Headband>
      <Typography variant="h6" sx={{ display: 'none', displayPrint: 'block', margin: 0 }}>
        Comptabilité du { dateFr(dateMin) } au { dateFr(dateMax) }
      </Typography>
      <ListItems
        name="relevesDeFactures"
        items={ items }
        page={ false }
        renderTableRow={ renderTableRow }
        columns={ columns }
      >
        <Button
          variant="outlined"
          onClick={ () => window.print() }
          sx={{ mt: theme.spacingResponsive, displayPrint: 'none' }}
        >Imprimer la liste</Button>
      </ListItems>
    </Box>
  )
}
