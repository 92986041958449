import { useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/system'
import { Drawer, IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import AppBar from './AppBar'
import Footer from './Footer'
import { AppContext } from '../context/AppContext'
import Aside from './Aside'
import IconMenu from './IconMenu'
import MainList from './MainList'
import useTheme from '../themes/material'
import { ColorModeContext } from '../context/ColorModeContext'

export default function Layout () {
  const app = useContext(AppContext)
  const { colorMode } = useContext(ColorModeContext)
  const theme = useTheme(colorMode)
  const [drawerOpened, setDrawerOpened] = useState(false)

  if (!app.user) {
    return (
      <Outlet />
    )
  }

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setDrawerOpened(!drawerOpened)
  }

  return (
    <>
      <Box sx={{
        display: { 'xs': 'none', 'lg': 'block' },
        position: 'fixed',
        width: '276px',
        height: '100vh',
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: colorMode === 'dark' ? theme.palette.background.paper : null,
        overflow: 'hidden',
        overflowY: 'auto'
      }}>
        <MainList />
      </Box>
      {/* <AppBar /> */}
      <Box sx={{
        marginLeft: { 'xs': 0, 'lg': '276px' },
        minHeight: '100vh',
        overflow: 'auto',
        position: 'relative'
      }}>
        <Outlet />
        <IconButton
          aria-label="delete"
          color="white"
          sx={{
            display: { 'xs': 'block', 'lg': 'none' },
            position: 'absolute',
            top: theme.spacing(2.5),
            left: theme.spacing(2.5)
          }}
          onClick={ () => {
            setDrawerOpened(true)
          } }  
        >
          <MenuIcon sx={{ display: 'block' }} />
        </IconButton>
        <Drawer
          anchor="left"
          open={ drawerOpened }
          sx={{
            display: { 'xs': 'block', 'lg': 'none' },
            position: 'absolute'
          }}
          onClose={ toggleDrawer() }
        >
          <MainList onNavigate={ () => {
            setDrawerOpened(false)
          } } />
        </Drawer>
      </Box>
      {/* <Footer /> */}
    </>
  )
}
