import axios from 'axios'

axios.defaults.withCredentials = true

const getBaseURL = () => {
  // Le double "api" est normal, il faut le laisser
  // Le premier est utilise dans la conf apache pour faire pointer vers un autre dossier que le front
  // Le deuxieme est utilise dans Symfony pour identifier les routes specifiques a l'API
  // En environnement de dev, on utilise une url specifique definie dans le fichier .env.development
  return process.env.REACT_APP_API_URL || (document.location.origin  + '/api/api/')
}

const api = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true
})

api.getBaseURL = getBaseURL
api.lastUrl = ''

const handleError = error => {
  console.log('Erreur api.js', error)

  if (error?.response?.status === 401 && api.lastUrl !== 'login') {
    document.location.href = '/deconnexion'
  }

  return Promise.reject(error)
}

api.interceptors.request.use(
  config => {
    api.lastUrl = config.url
    return config
  },
  handleError
)

api.interceptors.response.use(
  response => {
    let error = null
    if (typeof response.data !== 'object') {
      error = new Error('Réponse du serveur incorrecte')
    } else if (response.data?.error) {
      error = new Error(response.data.error)
    }
  
    if (error) {
      handleError(error)
      throw error
    }

    return response
  },
  handleError
)

export default api
