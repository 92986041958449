import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import useTheme from '../themes/material'

export default function WelcomeTitle () {
  const theme = useTheme()

  const sxEmphase = {
    fontSize: {
      xs: '1.4rem',
      sm: '1.6rem',
      md: '1.9rem',
      lg: '2.1rem',
      xl: '2.3rem'
    },
    fontWeight: 300,
    lineHeight: 1.1,
    textAlign: 'center'
  }

  return (
    <>
      <Typography variant="h1" sx={ sxEmphase }>
        Bienvenue dans votre
        <br />
        <span style={{ ...sxEmphase, fontSize: '1.5em', fontWeight: '500', fontStyle: 'italic' }}>
          espace client&nbsp;
        </span>
      </Typography>
      <Box sx={{ marginTop: '1rem', height: '.3rem', width: '6rem', backgroundColor: theme.palette.secondary.main }} />
    </>
  )
}
